import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { doGetUserForm } from '../../../controllers/formController';


export const getUserForm = createAsyncThunk(
    '/user/form',
    async (url, { rejectWithValue, getState, dispatch }) => {
        try {
            const data = doGetUserForm(url);
            return data;
        } catch (error) {
            if (!error?.response) {
                throw error;
            }
            return rejectWithValue(error?.response?.data)
        }
    })

const userFormLocalStorage = localStorage.getItem('user_form')
    ? JSON.parse(localStorage.getItem('user_form'))
    : []
// Slice
const usersSlices = createSlice({
    name: 'user',
    initialState: {
        form: userFormLocalStorage,
    },

    extraReducers: (builder) => {
        builder.addCase(getUserForm.pending, (state, action) => {
            state.loading = true;
            state.appErr = undefined;
            state.serverErr = undefined;
        });

        builder.addCase(getUserForm.fulfilled, (state, action) => {
            state.loading = true;
            state.form = action?.payload;
            state.appErr = undefined;
            state.serverErr = undefined;
        });

        builder.addCase(getUserForm.rejected, (state, action) => {
            state.loading = false;
            state.appErr = action?.payload?.message;
            state.serverErr = action?.error?.message;
        });
    },
})

export default usersSlices.reducer;