import React from 'react'
import { ThreeDots } from 'react-loader-spinner'

const Spinner = () => {
    return (
        <ThreeDots
            height="20"
            width="40"
            radius="8"
            color="#000089"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
        />
    )
}

export default Spinner