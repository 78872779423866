import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const getFormToken = createAsyncThunk(
    '/user/form/token',
    async (token, { rejectWithValue, getState, dispatch }) => {
        try {
            const data = token;
            return data;
        } catch (error) {
            if (!error?.response) {
                throw error;
            }
            return rejectWithValue(error?.response?.data)
        }
    })


const formsSlices = createSlice({
    name: 'forms',
    initialState: {
        token: "",
    },

    extraReducers: (builder) => {
        builder.addCase(getFormToken.pending, (state, action) => {
            state.loading = true;
            state.appErr = undefined;
            state.serverErr = undefined;
        });

        builder.addCase(getFormToken.fulfilled, (state, action) => {
            state.loading = true;
            state.token = action?.payload;
            state.appErr = undefined;
            state.serverErr = undefined;
        });

        builder.addCase(getFormToken.rejected, (state, action) => {
            state.loading = false;
            state.appErr = action?.payload?.message;
            state.serverErr = action?.error?.message;
        });
    },
})

export default formsSlices.reducer;