import React, { useState, useEffect } from 'react'
import { Box, Card, CardHeader, Avatar, IconButton, Switch, Tooltip } from '@mui/material';
import { red, grey, blue } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ModalFamilyMember from './ModalFamilyMember';
import { doDeleteFamilyMember, doSwitchFamilyMemberAnalysis } from '../controllers/registrationController';
import LaunchIcon from '@mui/icons-material/Launch';
import AlertDialog from './AlertDialog';

const _ = require('lodash');

const CardFamilyMember = ({ setFamilyMembers, familyMembers, compilation_id, onDeleteFamilyMember, onUpdateFamilyMember }) => {

    const [selectedFamilyMemberId, setSelectedFamilyMemberId] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        setOpenDialog(false);
        setOpenModal(false);
    }, [])

    const handleOpenModal = (user_id) => {
        setSelectedFamilyMemberId(user_id);
        setOpenModal(true);
        setOpenDialog(false);
    };

    const handleOpenDialog = (user_id) => {
        setSelectedFamilyMemberId(user_id);
        setOpenDialog(true);
        setOpenModal(false);
    };

    const handleClose = () => {
        setOpenModal(false);
        setSelectedFamilyMemberId(null);
    };


    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedFamilyMemberId(null);
    };

    const handleDeleteClick = async (user_id) => {
        try {
            const res = await doDeleteFamilyMember(compilation_id, user_id);
            // console.log('res', res);
            onDeleteFamilyMember(true);
        } catch (error) {
            // console.log(error);
        }
    };

    const handleSwitch = async (fm_id) => {
        try {
            const res = await doSwitchFamilyMemberAnalysis(compilation_id, fm_id);
            if (res.data.status === 'ok') {
                onUpdateFamilyMember(true);
                const updatedFamilyMembers = familyMembers.map((familyMember) => {
                    if (familyMember.id === fm_id) {
                        return {
                            ...familyMember,
                            to_analyze: !familyMember.to_analyze,
                        };
                    }
                    return familyMember;
                });
                setFamilyMembers(updatedFamilyMembers);

            }
        } catch (error) {
            // Handle the error
        }
    };




    return (
        <>
            {familyMembers.map((familyMember) => (
                <React.Fragment key={familyMember.id}>
                    <ModalFamilyMember
                        handleClose={handleClose}
                        handleOpen={handleOpenModal}
                        open={openModal && selectedFamilyMemberId === familyMember.id}
                        family_member_id={familyMember.id}
                        compilation_id={compilation_id}
                        onUpdateFamilyMember={onUpdateFamilyMember}
                    />
                    <AlertDialog
                        handleCloseDialog={handleCloseDialog}
                        handleOpenDialog={handleOpenDialog}
                        openDialog={openDialog && selectedFamilyMemberId === familyMember.id}
                        family_member_id={familyMember.id}
                        handleDeleteClick={handleDeleteClick}
                    />
                    <Card sx={{ mb: 1 }}>
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                    {familyMember && familyMember?.name?.charAt(0).toUpperCase()}
                                </Avatar>
                            }
                            action={
                                <>
                                    <Tooltip title={familyMember.to_analyze ? "Analizza" : "Non Analizzare"} placement="bottom">
                                        <IconButton disableRipple disableFocusRipple edge="end" aria-label="edit">
                                            <Switch
                                                checked={familyMember.to_analyze}
                                                onChange={() => handleSwitch(familyMember.id)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title={familyMember.dependent ? "A carico" : "Non a carico"} placement="bottom">
                                        <IconButton disableRipple disableFocusRipple onClick={() => handleOpenModal(familyMember.id)} edge="end" aria-label="edit">
                                            {/* <Typography>{familyMember.dependent === null ? "da definire" : (familyMember.dependent ? "A Carico" : "Non a Carico")}</Typography> */}
                                            <AttachMoneyIcon sx={{ color: familyMember.dependent ? blue[500] : grey[600] }} />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Modifica" placement="bottom">
                                        <IconButton onClick={() => handleOpenModal(familyMember.id)} edge="end" aria-label="edit">
                                            <LaunchIcon />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Elimina" placement="bottom">
                                        <IconButton onClick={() => handleOpenDialog(familyMember.id)} edge="end" aria-label="delete">
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>

                                </>
                            }
                            title={familyMember?.name + " " + familyMember?.surname}
                            subheader={_.capitalize(familyMember.family_member_type)}
                        />
                    </Card>

                </React.Fragment>
            ))}
        </>
    )
}

export default CardFamilyMember