import { Box, Typography } from '@mui/material'
import React from 'react'

const Landing = () => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: "center",
            minHeight: '70vh'
        }}>
            <Typography component="h1" variant="h5" color="#000089">
                Benvenuto nel Sondaggio Clienti AxiStudio
            </Typography>
            <Typography>
                Contatta un nostro assicuratore per analizzare le nostre proposte assicurative.
            </Typography>
        </Box>
    )
}

export default Landing