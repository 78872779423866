import axios from "axios";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const form_token = localStorage.getItem('form_token');

const headers = {
    headers: {
        "content-type": "application/json",
    },
};

async function doGetCompilation(formToken) {

    let res = await axios.get(API_ENDPOINT + `/compilations/${formToken}/family_member`, headers);
    if (res) return res.data;

}

async function doGetFamilyMemberCompilation(formToken, id_family_member) {
    let res = await axios.get(API_ENDPOINT + `/compilations/${formToken}/family_member/${id_family_member}`, headers);
    if (res) return res.data;
}

async function doGetRadioOptions(question_id) {

    let res = await axios.get(API_ENDPOINT + `/questions/${question_id}/answer_options`, headers);
    if (res) return res.data;

}

async function doGetOptionsAnswer(form_token, question_id, answer_id) {

    let res = await axios.get(API_ENDPOINT + `/compilations/${form_token}/questions/${question_id}/answer/${answer_id}`, headers);
    if (res) return res.data;

}


async function doGetAnswerText(form_token, question_id, answer_id,) {

    let res = await axios.get(API_ENDPOINT + `/compilations/${form_token}/questions/${question_id}/answer/${answer_id}`, headers);
    if (res) return res.data;
    // console.log('res', res)
}


async function doGetUserFamilyMembers(customer_id) {

    let res = await axios.get(API_ENDPOINT + `/customers/${customer_id}/family_members`, headers);
    if (res) return res.data;

}

async function doGetChildQuestion(form_token, question_id, answer_id) {

    let res = await axios.get(API_ENDPOINT + `/compilations/${form_token}/questions/${question_id}/answer/${answer_id}`, headers);
    if (res) return res.data;
}

export {
    doGetCompilation,
    doGetFamilyMemberCompilation,
    doGetRadioOptions,
    doGetOptionsAnswer,
    doGetAnswerText,
    doGetUserFamilyMembers,
    doGetChildQuestion
}