import React, { useEffect, useState } from 'react';

import { familyMembersOptions, isDependentOptions } from '../components/options';
import { doGetFamilyMember, doPutFamilyMember } from '../controllers/registrationController';
import { serializeFormDataToJson } from '../controllers/helpers';

import Select from 'react-select'

// @ MUI
import { Box, Typography, Modal, Grid, TextField, Button } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

// @ formik
import { useFormik } from 'formik';
import { familyMemberValidationSchema } from './formik-validation';

const fieldRequired = 'Campo richiesto';

const ModalFamilyMember = ({ handleOpen, handleClose, open, setOpen, compilation_id, family_member_id, onUpdateFamilyMember }) => {

    const isMobile = useMediaQuery('(max-width: 960px)');
    const [familyMember, setFamilyMember] = useState([]);
    const [selectedFamilyMember, setSelectedFamilyMember] = useState([]);
    const [selectedDependent, setSelectedDependent] = useState([]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '95%' : '35%', bgcolor: 'background.paper',
        border: '1px solid #d6e1f3',
        boxShadow: 1,
        p: 2,
    };

    const formik = useFormik({
        initialValues: {
            family_member_type: "",
            dependent: "",
            firstName: "",
            lastName: "",
            address: "",
            birth_date: new Date(),
            tax_code: "",
            birth_place: ""
        },
        validateOnChange: true,
        validationSchema: familyMemberValidationSchema,
        onSubmit: (values, { resetForm }) => {
            handleSubmit(values)
            resetForm({ values: "" })
        },
        validate: (values) => {
            const errors = {};
            const today = new Date();
            const selectedDate = new Date(values.birth_date);

            if (selectedDate.toDateString() === today.toDateString()) {
                errors.birth_date = fieldRequired;
            }
            // console.log('errors', errors);
            return errors;
        },
    })

    useEffect(() => {
        const getFamilyMember = async () => {
            try {
                const { data: { data: familyMember } } = await doGetFamilyMember(compilation_id, family_member_id);
                setFamilyMember(familyMember[0]);
                setSelectedFamilyMember(familyMember[0].family_member_type);
            } catch (error) {
                // console.log(error);
            }
        };
        getFamilyMember();
    }, [compilation_id, family_member_id]);

    const handleSubmit = async (values) => {

        const selectedBirthDate = new Date(formik.values.birth_date);
        const formattedDate = selectedBirthDate.toLocaleDateString('en-GB').split('/').reverse().join('-');

        // console.log('formik.values.family_member_type',formik.values.family_member_type.value)
        // console.log('formik.values.dependent',typeof(formik.values.dependent.value))

        const formData = new FormData();
        formData.append('family_member_type', formik.values.family_member_type.value);
        formData.append('dependent', formik.values.dependent.value);
        formData.append('name', formik.values.firstName);
        formData.append('surname', formik.values.lastName);
        formData.append('address', formik.values.address);
        formData.append('birth_date', formattedDate);
        formData.append('fiscal_code', formik.values.tax_code);
        formData.append('birth_place', formik.values.birth_place);

        // for (var pair of formData.entries()) {
        //     console.log(pair[0] + '<---- key | value ----> ' + pair[1]);
        // }

        const rawObject = serializeFormDataToJson(formData);

        // console.log('rawObject',rawObject)

        const updateFamilyMember = async () => {
            const { data } = await doPutFamilyMember(compilation_id, family_member_id, rawObject);
            if (data.status === "ok") {
                onUpdateFamilyMember(true);
                handleClose();
            } else if (data.status === "ko") {
                // console.log('data', data)
            }

        }

        updateFamilyMember();

    }

    useEffect(() => {
        if (familyMember) {
            const familyMemberTypeOpt = familyMembersOptions.find(option => option.value === familyMember.family_member_type);
            const dependentTypeOpt = isDependentOptions.find(option => option.value === familyMember.dependent);

            const birthDateFetched = new Date(familyMember.birth_date);

            formik.setValues({
                family_member_type: familyMemberTypeOpt,
                dependent: dependentTypeOpt,
                firstName: familyMember.name,
                lastName: familyMember.surname,
                address: familyMember.address,
                birth_date: birthDateFetched,
                birth_place: familyMember.birth_place,
                tax_code: familyMember.fiscal_code
            })
        }
    }, [familyMember]);

    const handleSelectChange = (selectedOpt) => {
        setSelectedFamilyMember(selectedOpt);
    }

    const handleSelectChange2 = (selectedOpt) => {
        setSelectedDependent(selectedOpt);
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.1)' } }}
        >
            <form onSubmit={formik.handleSubmit}>
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 3 }}>
                        Modifica familiare {formik.values.firstName + " " + formik.values.lastName}
                    </Typography>

                    <Grid item xs={12} sm={12} sx={{ marginBottom: 1 }}>
                        <Typography variant="subtitle1" sx={{ color: 'text.secondary', mb: 1 }}>Seleziona familiare:</Typography>
                        <Select
                            name="family_member_type"
                            onChange={(selectedOpt) => {
                                handleSelectChange(selectedOpt);
                                formik.setFieldValue("family_member_type", selectedOpt)
                            }}
                            options={familyMembersOptions}
                            value={formik.values.family_member_type}
                            className="basic-select"
                            classNamePrefix="select"
                        />
                        {formik.touched.family_member_type && formik.errors.family_member_type && (
                            <Typography variant="subtitle2" sx={{ color: 'error.main', fontWeight: 'light' }}>
                                {formik.errors.family_member_type.label}
                            </Typography>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={12} sx={{ marginBottom: 1 }}>
                        <Typography variant="subtitle1" sx={{ color: 'text.secondary', mb: 1 }}>A carico?</Typography>
                        <Select
                            name="dependent"
                            onChange={(selectedOpt) => {
                                handleSelectChange2(selectedOpt);
                                formik.setFieldValue("dependent", selectedOpt)
                            }}
                            options={isDependentOptions}
                            value={formik.values.dependent}
                            className="basic-select"
                            classNamePrefix="select"
                        />
                        {formik.touched.dependent && formik.errors.dependent && (
                            <Typography variant="subtitle2" sx={{ color: 'error.main', fontWeight: 'light' }}>
                                {formik.errors.dependent.label}
                            </Typography>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={12} sx={{ marginBottom: 1 }}>
                        <TextField
                            label="Nome"
                            fullWidth
                            name="firstName"
                            onChange={(event) => {
                                formik.handleChange(event.target.name)(event);
                                setSelectedFamilyMember(formik.values.family_member_type);
                            }}
                            value={formik.values.firstName}
                            onBlur={formik.handleBlur("firstName")}
                            id="firstName"
                            variant="standard"
                        />
                        <Typography variant="subtitle2" sx={{ color: 'error.main', fontWeight: 'light' }}>
                            {formik.touched.firstName && formik.errors.firstName}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} sx={{ marginBottom: 1 }}>
                        <TextField
                            label="Cognome"
                            fullWidth
                            name="lastName"
                            onChange={(event) => {
                                formik.handleChange(event.target.name)(event);
                                setSelectedFamilyMember(formik.values.family_member_type);
                            }}
                            value={formik.values.lastName}
                            onBlur={formik.handleBlur("lastName")}
                            id="lastName"
                            variant="standard"
                        />
                        <Typography variant="subtitle2" sx={{ color: 'error.main', fontWeight: 'light' }}>
                            {formik.touched.lastName && formik.errors.lastName}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} sx={{ marginBottom: 1 }}>
                        <TextField
                            label="Indirizzo"
                            fullWidth
                            name="address"
                            onChange={(event) => {
                                formik.handleChange(event.target.name)(event);
                                setSelectedFamilyMember(formik.values.family_member_type);
                            }}
                            value={formik.values.address}
                            onBlur={formik.handleBlur("address")}
                            id="address"
                            variant="standard"
                        />
                        <Typography variant="subtitle2" sx={{ color: 'error.main', fontWeight: 'light' }}>
                            {formik.touched.address && formik.errors.address}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} sx={{ marginBottom: 1 }}>
                        <MobileDatePicker
                            name="birth_date"
                            label="Data di Nascita"
                            inputFormat="DD/MM/YYYY"
                            value={formik.values.birth_date}
                            onChange={(birth_date) => {
                                formik.setFieldValue("birth_date", birth_date);
                                setSelectedFamilyMember(formik.values.family_member_type);
                            }}
                            renderInput={(params) => <TextField {...params}
                                variant="standard"
                                fullWidth
                            />
                            }
                        />
                        <Typography variant="subtitle2" sx={{ color: 'error.main', fontWeight: 'light' }}>
                            {formik.touched.birth_date && formik.errors.birth_date}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} sx={{ marginBottom: 1 }}>
                        <TextField
                            label="Luogo di Nascita"
                            fullWidth
                            name="birth_place"
                            onChange={(event) => {
                                formik.handleChange(event.target.name)(event);
                                setSelectedFamilyMember(formik.values.family_member_type);
                            }}
                            value={formik.values.birth_place}
                            onBlur={formik.handleBlur("birth_place")}
                            id="birth_place"
                            variant="standard"
                        />
                        <Typography variant="subtitle2" sx={{ color: 'error.main', fontWeight: 'light' }}>
                            {formik.touched.birth_place && formik.errors.birth_place}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} sx={{ marginBottom: 1 }}>
                        <TextField
                            label="Codice Fiscale"
                            fullWidth
                            name="tax_code"
                            onChange={(event) => {
                                formik.handleChange(event.target.name)(event);
                                setSelectedFamilyMember(formik.values.family_member_type);
                            }}
                            value={formik.values.tax_code}
                            onBlur={formik.handleBlur("tax_code")}
                            id="tax_code"
                            variant="standard"
                        />
                        <Typography variant="subtitle2" sx={{ color: 'error.main', fontWeight: 'light' }}>
                            {formik.touched.tax_code && formik.errors.tax_code}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} sx={{ mt: 3 }} display="flex" justifyContent="flex-end">
                        <Button type="submit" color="axaDeepBlue" variant="outlined" size="medium">
                            Salva
                        </Button>
                    </Grid>
                </Box>
            </form>
        </Modal >
    )
}

export default ModalFamilyMember