import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// @ MUI
import { Box } from '@mui/material';
import { fontSize, indentSize } from './mui-params';

// @ Components
import FormRadio from './FormRadio';
import FormText from './FormText';
import FormMultiple from './FormMultiple';

// @ Form
import { Formik } from 'formik';
import { textValidationSchema, radioValidationSchema } from './formik-validation'

// @ Logic
import { doGetRadioOptions, doGetOptionsAnswer, doGetAnswerText, doGetChildQuestion } from '../controllers/compilationController';
import { doPostAnswer, doPutAnswer } from '../controllers/answerController';
import { appendFormData } from '../controllers/helpers';
import { postIsAnswered } from "../redux/slices/questions/questionsSlices";

// @ Libraries
const _ = require('lodash');

// @ Component
const ChildQuestion = ({ q, family_member_id, indentLevel = 0, index }) => {

    const dispatch = useDispatch();

    const [childQuestion, setChildQuestion] = useState([]);
    const [childQuestionID, setChildQuestionID] = useState("");
    const [answeredText, setAnsweredText] = useState([]);
    const [answerOptions, setAnswerOptions] = useState([]);
    const [answeredOptions, setAnsweredOptions] = useState([]);
    const [answerProvided, setAnswerProvided] = useState("");
    const [loading, setLoading] = useState(false);
    const [saved, setSaved] = useState(false);
    const [visibleChildQuestionsTab, setVisibleChildQuestionsTab] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [updatedOption, setUpdatedOption] = useState(false);
    const [newMultipleChildQuestion, setNewMultipleChildQuestion] = useState([]);
    const [currentSelection, setCurrentSelection] = useState([]);


    const onSavedOption = (newUpdate) => {
        setUpdatedOption(newUpdate);
    };

    const onChangeSelect = (newArray) => {
        setCurrentSelection(newArray);
    };

    const formCompilation = useSelector(state => state.form)

    // @ Auth
    const { token } = formCompilation

    let tokenToUse = token;
    if (!tokenToUse) {
        tokenToUse = localStorage.getItem('form_token');
    }

    let initialValues = {};
    if (q.type === 'text') {
        initialValues = { inputText: answeredText?.answer_text || '' };
    } else if (q.type === 'float') {
        initialValues = { inputText: answeredText?.answer_float || '' };
    } else if (q.type === 'date') {
        initialValues = { inputText: answeredText?.answer_date || '' };
    }

    useEffect(() => {

        setErrorMessage(false);
        setLoading(false);
        setSaved(false);

        getRadioOptions();
        getAnswerText();
        getSelectOptions();

    }, [q, tokenToUse, updatedOption])

    const getAnswerText = async () => {

        if (q.type === 'text' || q.type === 'float' || q.type === 'date') {
            if (q.child_question_answers && q.child_question_answers.length > 0) {
                const { data } = await doGetAnswerText(tokenToUse, q?.id, q.child_question_answers[0]?.id);
                setAnsweredText(data?.compilation_answer);
            }
        }

    }

    const getRadioOptions = async () => {

        const { data } = await doGetRadioOptions(q.id);
        setAnswerOptions(data)
        if (q.child_question_answers && q.child_question_answers.length > 0) {
            if (q.type === "radio") {
                const { data } = await doGetOptionsAnswer(tokenToUse, q.id, q.child_question_answers[0]?.id);
                setAnsweredOptions(data.compilation_answer);
            };
        }

    }

    const getSelectOptions = async () => {

        setAnsweredOptions([]);
        setSelectedOptions([]);

        if (q.type === "multiple") {

            for (let i = 0; i < q.child_question_answers.length; i++) {
                const { data } = await doGetOptionsAnswer(tokenToUse, q.id, q.child_question_answers[i]?.id);
                selectedOptions.push(data.compilation_answer);

            }

            setAnsweredOptions(selectedOptions);

        }

        setUpdatedOption(false);

    }


    useEffect(() => {
        setErrorMessage(false);
        if (answeredOptions?.hasOwnProperty("child_question")) {
            setChildQuestion(answeredOptions?.child_question);
            setVisibleChildQuestionsTab(true);
        } else {
            setChildQuestion([]);
            setVisibleChildQuestionsTab(false);
        }

    }, [q, answeredOptions, updatedOption]);

    useEffect(() => {
        setErrorMessage(false);
        if (answeredText?.hasOwnProperty("child_question")) {
            setChildQuestion(answeredText?.child_question);
            setVisibleChildQuestionsTab(true);
        } else {
            setChildQuestion([]);
            setVisibleChildQuestionsTab(false);
        }

    }, [q, answeredText, updatedOption]);

    useEffect(() => {

        const multipleChildQuestion = getMultipleChildQuestion();
        setNewMultipleChildQuestion(multipleChildQuestion);

    }, [answeredOptions, updatedOption]);


    const getMultipleChildQuestion = () => {
        if (answeredOptions && answeredOptions?.length > 0) {
            return answeredOptions.filter((r) => r?.child_question).map((r) => r.child_question);
        }
        return [];
    };

    const handleSaveTextQuestion = async (values) => {

        setLoading(true);

        let formattedValue = values;

        if (q.type === 'date') {
            formattedValue = values;
        }

        const formData = appendFormData(q.type, formattedValue, family_member_id)

        try {
            const res = q.child_question_answers.length < 1
                ? await doPostAnswer(tokenToUse, q.id, formData)
                : await doPutAnswer(tokenToUse, q.id, answeredText?.id, formData);

            if (res.status === "ok") {
                setLoading(false);
                setSaved(true);
                setErrorMessage(false);
                dispatch(postIsAnswered(true));
                // if (res.data.compilation_answer.hasOwnProperty("child_question")) {
                if (res.data.compilation_answer.child_question) {
                    setChildQuestion(res.data.compilation_answer.child_question);
                    setVisibleChildQuestionsTab(true);
                } else {
                    setVisibleChildQuestionsTab(false);
                }
            } else if (res?.status === "ko") {
                setErrorMessage(res.error.message);
            }
            // console.log('res', res)
        } catch (error) {
            // console.log('error', error);
        }
    }

    const handleSaveRadioQuestion = async (answer_option_id) => {

        setLoading(true);

        const formData = appendFormData(q.type, answer_option_id, family_member_id)

        try {

            let res;

            if (answerProvided) {
                res = await doPutAnswer(tokenToUse, q.id, childQuestionID, formData);
            }

            else {

                if (q.child_question_answers.length < 1) {
                    res = await doPostAnswer(tokenToUse, q.id, formData);
                } else {
                    res = await doPutAnswer(tokenToUse, q.id, answeredOptions?.id, formData);
                }
            }

            if (res.status === "ok") {
                dispatch(postIsAnswered(true));
                setVisibleChildQuestionsTab(false);
                setLoading(false);
                setSaved(true);
                setAnswerProvided(true);
                setChildQuestionID(res?.data?.compilation_answer?.id || answeredOptions?.id)

                const newChildQuestionID = res?.data?.compilation_answer?.id || answeredOptions?.id;
                const childQuestionData = await doGetChildQuestion(tokenToUse, q?.id, newChildQuestionID);

                if (childQuestionData?.data?.compilation_answer?.hasOwnProperty("child_question")) {
                    setChildQuestion(childQuestionData?.data?.compilation_answer?.child_question);
                    setVisibleChildQuestionsTab(true);
                } else {
                    setVisibleChildQuestionsTab(false);
                }

            } else if (res.status === 'ko') {
                // console.log('result', res);
            }
        } catch (error) {
            // console.log('error', error);
        }

    }

    const handleSaveOption = async (option) => {

        try {

            const pickedOption = Array.isArray(option) ? option[option.length - 1] : option;

            const formData = appendFormData(q.type, pickedOption, family_member_id)

            const res = await doPostAnswer(token, q.id, formData);
            console.log(res);
            if (res.status === "ok") {
                console.log(res);
                const newAnswerOption = res.data.compilation_answer;
                setAnsweredOptions(prevOptions => [...prevOptions, newAnswerOption]);
            }

        } catch (error) {
            console.error('error', error);
        }

    };

    return (
        <>
            <Box
                paddingLeft={indentSize * indentLevel + 'em'} sx={{ marginTop: indentLevel > 0 ? '1em' : 0 }}>
                {
                    q.type === "multiple" &&
                    <Formik
                        enableReinitialize={true}
                        validationSchema={radioValidationSchema}
                        initialValues={''}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, index }) => (

                            <FormMultiple
                                key={q?.id}
                                fontSize={fontSize}
                                answerOptions={answerOptions}
                                answeredOptions={answeredOptions}
                                values={values}
                                errors={errors}
                                touched={touched}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                title={_.capitalize(q.text)}
                                questionId={q.id}
                                token={tokenToUse}
                                onSavedOption={onSavedOption}
                                onChangeSelect={onChangeSelect}
                                handleSaveOption={handleSaveOption}
                            />

                        )}
                    </Formik>
                }
                {
                    (q.type === "text" || q.type === "float" || q.type === "date") &&
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={textValidationSchema}>
                        {({ values, errors, touched, handleChange, handleBlur, index }) => (

                            <FormText
                                key={q?.id}
                                values={values}
                                errors={errors}
                                touched={touched}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                handleSaveTextQuestion={handleSaveTextQuestion}
                                fontSize={fontSize}
                                title={_.capitalize(q.text)}
                                saved={saved}
                                loading={loading}
                                question_id={q?.id}
                                answerOptions={answerOptions}
                                question_type={q?.type}
                                errorMessage={errorMessage}
                            />

                        )}
                    </Formik>
                }
                {
                    q.type === "radio" &&
                    <Formik enableReinitialize={true}
                        initialValues={{ inputRadio: answeredOptions?.answer_option?.id }}
                        validationSchema={radioValidationSchema}>
                        {({ values, errors, touched, handleChange, handleBlur, index }) => (
                            <FormRadio
                                key={q?.id}
                                fontSize={fontSize}
                                answerOptions={answerOptions}
                                values={values}
                                errors={errors}
                                touched={touched}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                handleSaveRadioQuestion={handleSaveRadioQuestion}
                                title={_.capitalize(q.text)}
                                question_id={q.id}
                                loading={loading}
                            />
                        )}
                    </Formik>
                }
            </Box>


            {
                // Text or Radio Input
                visibleChildQuestionsTab && (
                    <ChildQuestion
                        key={`k-${childQuestion.id}-${indentLevel}`}
                        q={childQuestion}
                        family_member_id={family_member_id}
                        indentLevel={indentLevel + 1}
                    />
                )

            }
            {
                // Multiple Select
                newMultipleChildQuestion && newMultipleChildQuestion.map((childQuestion, index) => (
                    <>
                        <ChildQuestion
                            key={`n-${childQuestion.id}-${indentLevel + 1}`}
                            q={childQuestion}
                            family_member_id={family_member_id}
                            indentLevel={indentLevel + 1}
                        />
                    </>
                ))
            }

        </>
    )
}

export default ChildQuestion