import React, { useState, useEffect } from 'react';
import Select from 'react-select'

// @MUI
import { Alert, Grid, Box, Stack, Button, FormControl, Typography, TextField } from "@mui/material";
import { useMediaQuery } from '@mui/material';
import { iconSize } from '../components/mui-params';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

// @ Formik
import { useFormik } from 'formik';
import { familyMemberValidationSchema } from '../components/formik-validation';

// @ Components
import CardFamilyMember from '../components/CardFamilyMember';
import Spinner from '../components/Spinner';
import { familyMembersOptions, isDependentOptions } from '../components/options'

// @Controllers
import { doPutToggleFamilyMembers } from '../controllers/formController';
import { doPostFamilyMemberRegistration, doGetFamilyMembers } from '../controllers/registrationController';

// @Helpers
import { logFormData } from '../controllers/helpers';


const fieldRequired = 'Campo richiesto';

const FormFamilyMembers = ({ compilation, onUpdateToggleFamilyMember }) => {

    const isMobile = useMediaQuery('(max-width: 960px)');

    const [showMemberForm, setShowMemberForm] = useState(false);
    const [selectedFamilyMember, setSelectedFamilyMember] = useState([]);
    const [selectedDependent, setSelectedDependent] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [fieldErrorMessage, setFieldErrorMessage] = useState("");
    const [hideSaveButton, setHideSaveButton] = useState(false);
    const [familyMembers, setFamilyMembers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alertTimeout, setAlertTimeout] = useState(false);

    //Modal States
    const [deleted, setDeleted] = useState(false);
    const [updated, setUpdated] = useState(false);

    const handleSelectFamilyMemberType = (selectedOpt) => {
        setSelectedFamilyMember(selectedOpt);
    }

    const handleSelectDependentType = (selectedOpt) => {
        setSelectedDependent(selectedOpt);
    }

    const onUpdateFamilyMember = (newUpdatedFamilyMember) => {
        setUpdated(newUpdatedFamilyMember);
    };

    const onDeleteFamilyMember = (newDeletedFamilyMember) => {
        setDeleted(newDeletedFamilyMember);
    };

    const handleButtonClick = (pressed) => {
        setShowMemberForm(pressed ? true : false);
        //or navigate to the same page
        setHideSaveButton(true);
    }

    const handleButtonQuit = () => {
        const toggleFamilyMembers = async () => {
            try {
                const { data } = await doPutToggleFamilyMembers();
                // console.log(data);
                onUpdateToggleFamilyMember(true);
            } catch (error) {
                // console.log(error);
            }
        };
        toggleFamilyMembers();
    }

    useEffect(() => {
        setIsSubmitted(false)
        setShowMemberForm(false);
        setUpdated(false);
        setDeleted(false);
        setErrorMessage(false);
        setFieldErrorMessage(false);
        setLoading(true);

        const getFamilyMembers = async () => {
            try {
                const { data: { data: familyMembers } } = await doGetFamilyMembers(compilation.id);
                setFamilyMembers(familyMembers);
                setLoading(false);
            } catch (error) {
                // console.log(error);
            }
        };
        getFamilyMembers();

    }, [compilation, deleted, updated, isSubmitted]);

    const formik = useFormik({
        initialValues: {
            family_member_type: "",
            dependent: "",
            firstName: "",
            lastName: "",
            address: "",
            birth_date: new Date(),
            tax_code: "",
            birth_place: ""
        },
        validateOnChange: true,
        validationSchema: familyMemberValidationSchema,
        onSubmit: (values, { resetForm }) => {
            handleSubmit(values)
            resetForm({ values: "" })
        },
        validate: (values) => {
            const errors = {};

            const today = new Date();
            const selectedDate = new Date(values.birth_date);

            if (selectedDate.toDateString() === today.toDateString()) {
                errors.birth_date = fieldRequired;
            }

            return errors;
        },
    })

    const handleSubmit = async (values) => {

        const selectedBirthDate = new Date(formik.values.birth_date);
        const formattedDate = selectedBirthDate.toLocaleDateString('en-GB').split('/').reverse().join('-');

        const formData = new FormData();
        formData.append('family_member_type', selectedFamilyMember.value);
        formData.append('dependent', selectedDependent.value === false ? 0 : selectedDependent.value);
        formData.append('name', formik.values.firstName);
        formData.append('surname', formik.values.lastName);
        formData.append('address', formik.values.address);
        formData.append('birth_date', formattedDate);
        formData.append('fiscal_code', formik.values.tax_code);
        formData.append('birth_place', formik.values.birth_place);

        // logFormData(formData);

        const postFamilyMembers = async () => {
            const { data } = await doPostFamilyMemberRegistration(compilation.id, formData);
            if (data.status === "ok") {
                setIsSubmitted(true);
                setHideSaveButton(false);
                setErrorMessage(false);
                setFieldErrorMessage(false);
                setIsError(false);
                setAlertTimeout(true);
            } else if (data.status === "ko") {
                setIsError(true);
                setFieldErrorMessage(data.error.validation[0].field);
                setErrorMessage(data.error.validation[0].message);
            }
        }

        postFamilyMembers();
    }


    return (

        <Grid container direction="row" alignItems="center"
            justifyContent="center" style={{ minHeight: '60vh' }}>

            <FormControl fullWidth>
                <form onSubmit={formik.handleSubmit}>

                    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography align={'center'} variant={isMobile ? 'h5' : 'h4'} gutterBottom color="#000089"
                                > Nucleo familiare del contraente
                                </Typography>
                            </Grid>
                        </Grid>
                        <Stack direction="row" sx={{ mt: 3, mb: 3 }} spacing={2}>
                            <Button fullWidth
                                id="addFamilyMember"
                                onClick={(pressed) => handleButtonClick(pressed)}
                                variant="outlined"
                                startIcon={<AddIcon
                                    style={iconSize} />}
                                sx={{ color: "#000089", borderColor: "#000089" }}
                            >
                                Aggiungi
                            </Button>
                            <Button
                                fullWidth
                                onClick={(pressed) => handleButtonQuit(pressed)}
                                variant="outlined"
                                startIcon={<CloseIcon
                                    style={iconSize} />}
                                sx={{ color: "#000089", borderColor: "#000089" }}
                            >
                                Termina
                            </Button>
                        </Stack>
                        {isSubmitted && (
                            <>
                                <Box sx={{ width: 1 }}>
                                    <Alert sx={{ mb: 3 }} severity="success">Componente della famiglia aggiunto</Alert>
                                    <CardFamilyMember
                                        familyMembers={familyMembers}
                                        compilation_id={compilation?.id}
                                        onDeleteFamilyMember={onDeleteFamilyMember}
                                        onUpdateFamilyMember={onUpdateFamilyMember}
                                        setFamilyMembers={setFamilyMembers}
                                    />
                                </Box>
                            </>
                        )}
                        {isError && <Alert severity="error">{errorMessage} - {fieldErrorMessage}</Alert>}
                    </Box>

                    {!isSubmitted &&
                        <>
                            <Box>
                                {!showMemberForm &&
                                    (
                                        !loading ? (familyMembers.length > 0 ?
                                            <CardFamilyMember
                                                familyMembers={familyMembers}
                                                compilation_id={compilation?.id}
                                                onDeleteFamilyMember={onDeleteFamilyMember}
                                                onUpdateFamilyMember={onUpdateFamilyMember}
                                            /> : <Typography align="center">Nessun componente familiare inserito</Typography>) :

                                            <Grid
                                                container
                                                alignItems="center"
                                                justifyContent="center"
                                                style={{ minHeight: '8vh' }}
                                            >
                                                <Grid item>
                                                    <Spinner />
                                                </Grid>
                                            </Grid>

                                    )
                                }

                                {showMemberForm && (
                                    <>
                                        <Grid item xs={12} sm={12} sx={{ marginBottom: 1 }}>
                                            <Typography variant="subtitle1" sx={{ color: 'text.secondary', mb: 1 }}>Seleziona familiare:</Typography>
                                            <Select
                                                name="family_member_type"
                                                onChange={(selectedOpt) => {
                                                    handleSelectFamilyMemberType(selectedOpt);
                                                    formik.setFieldValue("family_member_type", selectedOpt)
                                                }}
                                                options={familyMembersOptions}
                                                value={selectedFamilyMember}
                                                className="basic-select"
                                                classNamePrefix="select"
                                            />
                                            {/* TODO::FIX*/}
                                            {formik.touched.family_member_type && formik.errors.family_member_type && (
                                                <Typography variant="subtitle2" sx={{ color: 'error.main', fontWeight: 'light' }}>
                                                    {formik.errors.family_member_type.label}
                                                </Typography>
                                            )}
                                        </Grid>

                                        <Grid item xs={12} sm={12} sx={{ marginBottom: 1 }}>
                                            <Typography variant="subtitle1" sx={{ color: 'text.secondary', mb: 1 }}>A carico?</Typography>
                                            <Select
                                                name="dependent"
                                                onChange={(selectedOpt) => {
                                                    handleSelectDependentType(selectedOpt);
                                                    formik.setFieldValue("dependent", selectedOpt)
                                                }}
                                                options={isDependentOptions}
                                                value={selectedDependent}
                                                className="basic-select"
                                                classNamePrefix="select"
                                            />
                                            {/* TODO::FIX*/}
                                            {formik.touched.family_member_type && formik.errors.family_member_type && (
                                                <Typography variant="subtitle2" sx={{ color: 'error.main', fontWeight: 'light' }}>
                                                    {formik.errors.family_member_type.label}
                                                </Typography>
                                            )}
                                        </Grid>

                                        <Grid item xs={12} sm={12} sx={{ marginBottom: 1 }}>
                                            <TextField
                                                label="Nome"
                                                fullWidth
                                                name="firstName"
                                                onChange={formik.handleChange("firstName")}
                                                value={formik.values.firstName}
                                                onBlur={formik.handleBlur("firstName")}
                                                id="firstName"
                                                variant="standard"
                                            />
                                            <Typography variant="subtitle2" sx={{ color: 'error.main', fontWeight: 'light' }}>
                                                {formik.touched.firstName && formik.errors.firstName}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={12} sx={{ marginBottom: 1 }}>

                                            <TextField
                                                label="Cognome"
                                                fullWidth
                                                name="lastName"
                                                onChange={formik.handleChange("lastName")}
                                                value={formik.values.lastName}
                                                onBlur={formik.handleBlur("lastName")}
                                                id="lastName"
                                                variant="standard"
                                            />
                                            <Typography variant="subtitle2" sx={{ color: 'error.main', fontWeight: 'light' }}>
                                                {formik.touched.lastName && formik.errors.lastName}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={12} sx={{ marginBottom: 1 }}>
                                            <TextField
                                                label="Indirizzo"
                                                fullWidth
                                                name="address"
                                                onChange={formik.handleChange("address")}
                                                value={formik.values.address}
                                                onBlur={formik.handleBlur("address")}
                                                id="address"
                                                variant="standard"
                                            />
                                            <Typography variant="subtitle2" sx={{ color: 'error.main', fontWeight: 'light' }}>
                                                {formik.touched.address && formik.errors.address}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={12} sx={{ marginBottom: 1 }}>
                                            <MobileDatePicker
                                                name="birth_date"
                                                label="Data di Nascita"
                                                inputFormat="DD/MM/YYYY"
                                                // value={formik.values.DOB}
                                                // onChange={(birth_date) => formik.setFieldValue("birth_date", birth_date)}
                                                value={formik.values.birth_date}
                                                onChange={(birth_date) => formik.setFieldValue("birth_date", birth_date)}
                                                renderInput={(params) => <TextField {...params}
                                                    variant="standard"
                                                    fullWidth
                                                />
                                                }
                                            />
                                            <Typography variant="subtitle2" sx={{ color: 'error.main', fontWeight: 'light' }}>
                                                {formik.touched.birth_date && formik.errors.birth_date}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={12} sx={{ marginBottom: 1 }}>

                                            <TextField
                                                label="Luogo di Nascita"
                                                fullWidth
                                                name="birthPlace"
                                                onChange={formik.handleChange("birth_place")}
                                                value={formik.values.birth_place}
                                                onBlur={formik.handleBlur("birth_place")}
                                                id="birthPlace"
                                                variant="standard"
                                            />
                                            <Typography variant="subtitle2" sx={{ color: 'error.main', fontWeight: 'light' }}>
                                                {formik.touched.birth_place && formik.errors.birth_place}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={12} sx={{ marginBottom: 1 }}>
                                            <TextField
                                                id="tax_code"
                                                name="tax_code"
                                                label="Codice Fiscale"
                                                maxLength="10"
                                                fullWidth
                                                variant="standard"
                                                onChange={formik.handleChange("tax_code")}
                                                value={formik.values.tax_code}
                                                onBlur={formik.handleBlur("tax_code")}
                                            />
                                            <Typography variant="subtitle2" sx={{ color: 'error.main', fontWeight: 'light' }}>
                                                {formik.touched.tax_code && formik.errors.tax_code}
                                            </Typography>
                                        </Grid>

                                    </>
                                )}
                                <Grid item xs={12} sm={12} sx={{ mt: 3 }} display="flex" justifyContent="flex-end">
                                    {hideSaveButton && (<Button type="submit" color="axaDeepBlue" variant="outlined" size="medium">
                                        Salva
                                    </Button>)}

                                </Grid>

                            </Box>
                        </>
                    }

                </form>
            </FormControl>
        </Grid>

    );
};

export default FormFamilyMembers;
