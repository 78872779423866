import axios from "axios";

const API = process.env.REACT_APP_API_ENDPOINT;

async function doPostCustomerRegistration(formData) {

    const headers = {
        headers: {
            "x-language": "en"
        }
    };

    let response = await axios.post(API + `/customers`, formData, headers)
    if (response) return response.data;
}

async function doGetFamilyMembers(customer_id) {
    const headers = {
        headers: {
            "x-language": "en"
        }
    };

    let response = await axios.get(API + `/customers/${customer_id}/family_members`, headers)
    if (response) return response;
}

async function doPutFamilyMember(customer_id, family_member_id, rawObject) {
    const headers = {
        headers: {
            "x-language": "en"
        }
    };

    let response = await axios.put(API + `/customers/${customer_id}/family_members/${family_member_id}`, rawObject, headers)
    if (response) return response;
}


async function doGetFamilyMember(customer_id, family_member_id) {
    const headers = {
        headers: {
            "x-language": "en"
        }
    };

    let response = await axios.get(API + `/customers/${customer_id}/family_members/${family_member_id}`, headers)
    if (response) return response;
}

async function doDeleteFamilyMember(customer_id, family_member_id) {
    const headers = {
        headers: {
            "x-language": "en"
        }
    };

    let response = await axios.delete(API + `/customers/${customer_id}/family_members/${family_member_id}`, headers)
    if (response) return response;
}

async function doPostFamilyMemberRegistration(customer_id, formData) {

    const headers = {
        headers: {
            "x-language": "en"
        }
    };

    const form_token = localStorage.getItem('form_token');

    // console.log('form_token', form_token);

    let response = await axios.post(API + `/customers/${customer_id}/family_members`, formData, headers)
    if (response) return response;

}

async function doSwitchFamilyMemberAnalysis(compilation_id, fm_id) {

    const headers = {
        headers: {
            "x-language": "en"
        }
    };

    let response = await axios.put(API + `/customers/${compilation_id}/family_members/${fm_id}/to_analyze`, headers)
    if (response) return response;
}

export {
    doPostCustomerRegistration,
    doPostFamilyMemberRegistration,
    doGetFamilyMembers,
    doGetFamilyMember,
    doDeleteFamilyMember,
    doPutFamilyMember,
    doSwitchFamilyMemberAnalysis
}