import * as yup from 'yup'
const fieldRequired = 'Campo richiesto';

// export const textValidationSchema =
//     yup.object({
//         inputText: yup.string().required('Il campo non può essere vuoto')
//     });

export const textValidationSchema = yup.object({
    inputText: yup
        .string()
        .when('enableValidation', {
            is: true,
            then: yup.string().required("Il campo non può essere vuoto"),
            otherwise: yup.string()
        })
});

export const radioValidationSchema =
    yup.object().shape({
        inputRadio: yup.string().required("Seleziona un'opzione")
    });

export const familyMemberValidationSchema =
    yup.object({
        family_member_type: yup.object().shape({
            value: yup.string().required(fieldRequired),
            label: yup.string().required(fieldRequired),
        }),
        dependent: yup.object().shape({
            value: yup.string().required(fieldRequired),
            label: yup.string().required(fieldRequired),
        }),
        firstName: yup.string().required(fieldRequired),
        lastName: yup.string().required(fieldRequired),
        address: yup.string().required(fieldRequired),
        birth_date: yup.string().required(fieldRequired),
        // tax_code: yup.string().required(fieldRequired).length(16, "Il codice fiscale deve essere composto da 16 caratteri"),
        birth_place: yup.string().required(fieldRequired),
    })

export const registerValidationSchema = yup.object({
    firstName: yup.string().required(fieldRequired),
    lastName: yup.string().required(fieldRequired),
    gender: yup.string().required(fieldRequired),
    DOB: yup.string().required(fieldRequired),
    city_birth: yup.string().required(fieldRequired),
    district: yup.string().min(2, 'Minimo 2 caratteri').max(4, 'Massimo 4 caratteri').required(fieldRequired),
    // tax_code: yup.string().max(16, '16 caratteri richiesti').required('Campo richiesto'),
    zip: yup.string().min(5, 'Campo richiesto, minimo 4 lettere').required(fieldRequired),
    country: yup.string().max(2, 'Massimo 2 caratteri'),
    email: yup.string().email('Formato indirizzo email non valido').required(fieldRequired),
    phone: yup.string().required(fieldRequired),
})