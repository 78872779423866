import React from 'react'
import Form from './Form';

import { Paper, Button, Grid, Typography, Box } from '@mui/material';
import { commonStyles } from "../components/commonStyles";

const FormPanel = ({ fm, family_member_id, family_member, currentStepIndex, handlePrevStep, handleNextStep, compilation, onUpdateToggleFamilyMember }) => {

    return (

        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>

            {(!family_member?.to_analyze && fm) ?
                <Box
                    sx={{
                        ...commonStyles,
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: '40vh'
                    }}>
                    <Typography>Membro della famiglia non soggetto ad analisi</Typography>
                </Box>
                :
                <>

                    <Form
                        onUpdateToggleFamilyMember={onUpdateToggleFamilyMember}
                        key={
                            fm
                                ? compilation[0].steps[currentStepIndex]?.id
                                : compilation?.steps[currentStepIndex]?.id
                        }
                        data={
                            fm
                                ? compilation[0].steps[currentStepIndex]
                                : compilation?.steps[currentStepIndex]
                        }
                        fm={fm}
                        family_member_id={family_member_id}
                    />

                    <Grid item xs={4} sm={1} sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                        <Button disabled={currentStepIndex === 0} onClick={handlePrevStep} color="axaDeepBlue"
                            variant="outlined" size="medium">
                            Indietro
                        </Button>
                        <Button onClick={handleNextStep} color="axaDeepBlue" variant="outlined" size="medium">
                            Prossimo
                        </Button>
                    </Grid>
                </>
            }

        </Paper>

    )
}

export default FormPanel

