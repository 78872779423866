import React, { useEffect, useState } from 'react';

// @Logic
import { doGetCompilation, doGetUserFamilyMembers, doGetFamilyMemberCompilation } from '../controllers/compilationController';
import { doPutToggleFamilyMembers } from '../controllers/formController';

// @Pages
import FormPanel from './FormPanel'
import FormFamilyMembers from "./FormFamilyMembers";

// @ Components
import StatusBar from '../components/StatusBar';
import { Stepper, Step } from 'react-form-stepper';
import { getStepperStyle } from '../stepperStyle.js';

// @MUI
import { Paper, Typography, Stack, Grid } from '@mui/material';
import { styled } from '@mui/system';

// @Redux
import { getFormToken } from '../redux/slices/forms/formsSlices';
import { useDispatch, useSelector } from 'react-redux';
import { postIsAnswered } from "../redux/slices/questions/questionsSlices";

const FixedStyling = styled('div')({
    background: 'white',
    position: '-webkit-sticky',
    position: 'sticky',
    top: 10,
    bottom: 10,
    paddingTop: '50px',
    paddingBottom: '5px',
    zIndex: 5,
    boxShadow: `0 12px 12px -12px rgba(0, 0, 0, 0.1)`,
});

const Forms = () => {

    const dispatch = useDispatch();
    const [token, setToken] = useState('');
    const [compilation, setCompilation] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [createFamilyMembers, setCreateFamilyMembers] = useState(false);
    const [disableFamilyMembers, setDisableFamilyMembers] = useState(false);
    const [familyMembers, setFamilyMembers] = useState([]);
    const [fmCompilations, setFmCompilations] = useState([]);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [stepperSteps, setStepperSteps] = useState([]);
    const [stepperStepsFm, setStepperStepsFm] = useState([]);
    const onUpdateToggleFamilyMember = (newUpdatedToggleFamilyMember) => {
        setDisableFamilyMembers(newUpdatedToggleFamilyMember);
    };


    const isAnswered = useSelector(state => state.questions.isAnswered)

    const getUserFamilyMemberCompilations = async (fMembers) => {

        if (compilation?.id && fMembers.length > 0) {
            const fmCompilationsData = await Promise.all(
                fMembers.map(async (fm) => {
                    const { data } = await doGetFamilyMemberCompilation(token, fm.id);
                    return data;
                })
            );

            setFmCompilations(fmCompilationsData);

        }
    };

    const getUserFamilyMembers = async (compilationData) => {
        if (compilationData?.id) {
            const { data } = await doGetUserFamilyMembers(compilationData.id);
            if (data.length === 0) {
                setCreateFamilyMembers(true)
            }
            setFamilyMembers(data);
            getUserFamilyMemberCompilations(data);
        }
    };

    useEffect(() => {
        setDisableFamilyMembers(false);
        setCurrentStepIndex(currentStepIndex);

        const url = window.location.href;
        const parts = url.split('/');
        const startI = parts.indexOf('compilations');
        const endI = parts.indexOf('family_member');
        const formToken = parts.slice(startI + 1, endI).join('/');

        if (formToken !== token) {
            setToken(formToken)
        }

        if (formToken) {
            const getCompilation = async () => {
                const { data } = await doGetCompilation(formToken);
                if (data && data.length > 0 && JSON.stringify(data[0]) !== JSON.stringify(compilation)) {
                    setCompilation(data[0])
                    if (data[0]?.steps.length > 0) {
                        dispatch(getFormToken(formToken));
                        localStorage.setItem('form_token', JSON.stringify(formToken))
                    }
                }
                setLoading(false)
            }
            setLoading(true)
            getCompilation();
        }

        getUserFamilyMembers(compilation);

    }, [token, dispatch, compilation, disableFamilyMembers, currentStepIndex, isAnswered]);

    // --- START Steps Calculation

    compilation?.steps?.sort((a, b) => {
        if (a.weight === null && b.weight === null) return 0;
        if (a.weight === null) return 1;
        if (b.weight === null) return -1;
        return a.weight - b.weight;
    });

    const allStepperFmSteps = [];

    useEffect(() => {

        fmCompilations.forEach((compilations, i) => {

            compilations.forEach((fmCompilation, j) => {

                if (fmCompilation?.steps) {
                    const sortedFmSteps = fmCompilation.steps.sort((a, b) => {
                        if (a.weight === null && b.weight === null) return 0;
                        if (a.weight === null) return 1;
                        if (b.weight === null) return -1;
                        return a.weight - b.weight;
                    });

                    const stepperFmSteps = sortedFmSteps.map((step, index) => {
                        return {
                            id: index + 1,
                            completed: step?.isFulFilled,
                            active: index,
                            onClick: () => handleStepClick(index),
                        };
                    });

                    allStepperFmSteps.push(stepperFmSteps);
                }
            });
        });

        setStepperStepsFm(allStepperFmSteps);

    }, [fmCompilations, isAnswered]);


    useEffect(() => {

        dispatch(postIsAnswered(false));

        const sortedSteps = compilation?.steps?.map(({ weight, questions, ...step }) => step);

        const updatedStepperSteps = sortedSteps?.map((step, index) => {
            return {
                key: index + 1,
                completed: step?.isFulFilled,
                active: index,
                onClick: () => handleStepClick(index),
            };
        });

        setStepperSteps(updatedStepperSteps);

    }, [compilation, isAnswered])


    // --- END Steps Calculation

    const handleStepClick = (stepIndex) => {
        setCurrentStepIndex(stepIndex);
    };

    const handlePrevStep = () => {
        if (currentStepIndex > 0) {
            setCurrentStepIndex(currentStepIndex - 1);
        }
    };

    const handleNextStep = () => {
        if (currentStepIndex < compilation?.steps?.length - 1) {
            setCurrentStepIndex(currentStepIndex + 1);
        }
    }

    const handleTabChange = (index) => {
        const inputIndex = index - 1;
        const currentTabIndex = familyMembers.findIndex((fm, index) => (index) === inputIndex);
        setCurrentTabIndex(currentTabIndex + 1);
        getUserFamilyMembers(compilation);
    };

    const handleButtonEdit = () => {
        const toggleFamilyMembers = async () => {
            try {
                const { data } = await doPutToggleFamilyMembers();
                onUpdateToggleFamilyMember(true);
            } catch (error) {
                // console.log(error);
            }
        };
        toggleFamilyMembers();
    }

    // IMPORTANT
    if (!compilation || !Array.isArray(compilation.steps)) return null;

    return (
        <>
            {compilation && !compilation?.family_filled ? (
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <FormFamilyMembers onUpdateToggleFamilyMember={onUpdateToggleFamilyMember} compilation={compilation} />
                </Paper>
            ) : (
                <>
                    {currentTabIndex === 0 ? (
                        <>
                            <FixedStyling>
                                <StatusBar currentTabIndex={currentTabIndex} familyMembers={familyMembers} handleTabChange={handleTabChange} handleButtonEdit={handleButtonEdit} />
                                <Stepper
                                    steps={stepperSteps}
                                    styleConfig={getStepperStyle()}
                                    className={'stepper'}
                                    stepClassName={'stepper__step'}
                                // activeStep={currentStepIndex}
                                />
                                <Grid>
                                    <Stack direction="row"
                                        sx={{
                                            justifyContent: "center"
                                        }}
                                        spacing={2}>
                                        <Typography variant="h6" color="#000089" gutterBottom>Step: {currentStepIndex + 1}</Typography>
                                    </Stack>
                                </Grid>
                            </FixedStyling>
                            <FormPanel
                                compilation={compilation}
                                index={0}
                                onUpdateToggleFamilyMember={onUpdateToggleFamilyMember}
                                currentStepIndex={currentStepIndex}
                                handlePrevStep={handlePrevStep}
                                handleNextStep={handleNextStep}
                                fm={false}
                            />
                        </>
                    ) : (
                        fmCompilations.length > 0 && fmCompilations[currentTabIndex - 1] && !fmCompilations[currentTabIndex - 1].dependent && (
                            <>
                                <FixedStyling>
                                    <StatusBar currentTabIndex={currentTabIndex} familyMembers={familyMembers} handleTabChange={handleTabChange} handleButtonEdit={handleButtonEdit} />
                                    <Stepper
                                        steps={stepperStepsFm[currentTabIndex - 1]}
                                        styleConfig={getStepperStyle()}
                                        className={'stepper'}
                                        stepClassName={'stepper__step'}
                                    // activeStep={currentStepIndex}
                                    />
                                    <Grid>
                                        <Stack direction="row"
                                            sx={{
                                                justifyContent: "center"
                                            }}
                                            spacing={2}>
                                            <Typography variant="h6" color="#000089" gutterBottom>Step: {currentStepIndex + 1}</Typography>
                                        </Stack>
                                    </Grid>
                                </FixedStyling>
                                <FormPanel
                                    compilation={fmCompilations[currentTabIndex - 1]}
                                    family_member_id={familyMembers[currentTabIndex - 1]?.id}
                                    family_member={familyMembers[currentTabIndex - 1]}
                                    onUpdateToggleFamilyMember={onUpdateToggleFamilyMember}
                                    currentStepIndex={currentStepIndex}
                                    handlePrevStep={handlePrevStep}
                                    handleNextStep={handleNextStep}
                                    fm={true}
                                />
                            </>
                        )
                    )}
                </>
            )
            }
        </>
    );


}


export default Forms