import './App.css';
import React, { useEffect, useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import AxaLogo from './assets/company_logo.png'

// @Pages
import Forms from "./pages/Forms";
import Registration from "./pages/Registration";
import Welcome from "./pages/Welcome"
import Completed from './pages/Completed';
import NotFound from './pages/NotFound';
import Landing from './pages/Landing';

// @components
import Footer from './components/Footer'

// @MUI
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// @Controller
import { doGetForms } from './controllers/formController';

const locale = "it-IT"

// @Create Theme for MUI Interface
const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

// @Corporate theme Axa
const theme = createTheme({
    palette: {
        axaDeepBlue: createColor('#000089'),
    },
    typography: {
        fontFamily: [
            'Mukta Malar',
            'sans-serif',
        ].join(','),
    },
});

function App() {

    const [forms, setForms] = useState([]);

    useEffect(() => {
        loadForms();
    }, [])

    const loadForms = () => {
        doGetForms()
            .then((res) => setForms(res.data))
            .catch(error => console.log('error', error))
    }

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <AppBar style={{ background: '#d6e1f3' }} position="sticky" sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '5vh'
                    }}>
                        <Toolbar>
                            <Avatar component="a" href="/" sx={{ marginRight: '1rem' }} alt="Axa Assicurazioni" src={AxaLogo} variant="square" />
                            <Typography component="a" href="/" sx={{ textDecoration: 'none' }} variant="h6" color="#000089" noWrap>
                                AXI Studio
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Container component="main" maxWidth="lg">
                        <Routes>
                            {forms.map((path, index) => (
                                <Route path={path.url} key={index} element={<Welcome />} />
                            ))}
                            <Route path='/registration' element={<Registration />} />
                            <Route path='/completed' element={<Completed />} />
                            <Route path='/compilations/*' element={<Forms />} />
                            <Route path='/' element={<Landing />} />
                            <Route path='/*' element={<NotFound />} />
                        </Routes>
                    </Container>
                    <Footer />
                </ThemeProvider>
            </LocalizationProvider>
        </>
    );
}

export default App;
