import React from 'react';
import { Box, Typography, useTheme } from "@mui/material";
import { commonStyles } from '../components/commonStyles';

const Completed = () => {

    const theme = useTheme();

    return (
        <Box
            sx={{
                ...commonStyles,
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Typography component="h1" variant="h5" color={theme.palette.axaDeepBlue.main}>
                Sondaggio Terminato
            </Typography>
        </Box >
    )
}

export default Completed  