import React from 'react'
import { Tab, Tabs, Box, Button, Stack } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { iconSize } from './../components/mui-params';

const StatusBar = ({ familyMembers, currentTabIndex, handleTabChange, handleButtonEdit }) => {

    return (
        <Box sx={{ width: '100%', my: { xs: 2, md: 2 } }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Stack direction={{ xs: 'column', md: 'row' }}
                    sx={{
                        mt: 3,
                        mb: 3,
                        justifyContent: "space-between"
                    }}
                    spacing={2}>
                    <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                        value={currentTabIndex}
                        onChange={(event, index) => handleTabChange(index)}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: "#000089",
                            }
                        }}

                    >
                        <Tab
                            sx={{
                                color: "#000089",
                                "&.Mui-selected": {
                                    color: "#000089",
                                },
                            }}
                            key={0} label="Contraente" >
                        </Tab>

                        {familyMembers?.map((fm, fmIndex) => (
                            <Tab
                                sx={{
                                    color: !fm.dependent ? "grey" : "#000089",
                                    "&.Mui-selected": {
                                        color: !fm.dependent ? "grey" : "#000089",
                                    },
                                }}
                                key={fmIndex}
                                label={`${fm.name} ${fm.surname} (${fm.family_member_type})`}
                            // label={`${fm.name} ${fm.surname} (${fm.family_member_type}) ${fmIndex} ${fm.id}`}
                            />
                        ))}
                    </Tabs>

                    <Box sx={{ width: { xs: '100%', md: 'auto' }, mt: { xs: 2, md: 0 } }}>
                        <Button
                            id="addFamilyMember"
                            onClick={(pressed) => handleButtonEdit(pressed)}
                            variant="outlined"
                            startIcon={<EditIcon style={iconSize} />}
                            fullWidth={true}
                            sx={{ color: "#000089", borderColor: "#000089" }}
                        >
                            Modifica Nucleo Familiare
                        </Button>
                    </Box>
                </Stack>
            </Box>
        </Box>
    )
}

export default StatusBar