import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { postIsAnswered } from '../redux/slices/questions/questionsSlices'

import ChildQuestion from './ChildQuestion';

// @ Logic
import { doGetRadioOptions, doGetOptionsAnswer, doGetAnswerText, doGetChildQuestion } from '../controllers/compilationController';
import { doPostAnswer, doPutAnswer } from '../controllers/answerController';
import { appendFormData } from '../controllers/helpers';

// @ Form
import { Formik } from 'formik';
import { textValidationSchema, radioValidationSchema } from './formik-validation'

// @ MUI
import FormRadio from './FormRadio';
import FormText from './FormText';
import FormMultiple from './FormMultiple';
import { Paper } from '@mui/material';
import { fontSize } from './mui-params';

const _ = require('lodash');

const Question = ({ q, index, fm, family_member_id }) => {

    const dispatch = useDispatch();

    const [answerOptions, setAnswerOptions] = useState([]);
    const [answeredOptions, setAnsweredOptions] = useState([]);
    const [childQuestion, setChildQuestion] = useState([]);
    const [answerText, setAnswerText] = useState({
        answer_text: '',
        answer_float: '',
        answer_date: ''
    });

    const [answerProvided, setAnswerProvided] = useState();
    const [childQuestionID, setChildQuestionID] = useState();

    const [loading, setLoading] = useState(false);
    const [saved, setSaved] = useState(false);
    const [visibleChildQuestionsTab, setVisibleChildQuestionsTab] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [updatedOption, setUpdatedOption] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [newMultipleChildQuestion, setNewMultipleChildQuestion] = useState([]);

    const formCompilation = useSelector(state => state.form)
    const { token } = formCompilation

    let tokenToUse = token;
    if (!tokenToUse) {
        tokenToUse = localStorage.getItem('form_token');
    }

    const onSavedOption = (newUpdate) => {
        setUpdatedOption(newUpdate);
    };

    let initialValues = {};
    if (q.type === 'text') {
        initialValues = {
            inputText: answerText?.answer_text || '',
            enableValidation: q?.text !== 'NOTE/CONSIDERAZIONI'
        };
    } else if (q.type === 'float') {
        initialValues = { inputText: answerText?.answer_float || '' };
    } else if (q.type === 'date') {
        initialValues = { inputText: answerText?.answer_date || '' };
    }

    useEffect(() => {

        setLoading(false);
        setSaved(false);
        setErrorMessage(false);

        getAnswerOptions();
        getAnswerText();

    }, [q, tokenToUse, updatedOption]);



    const getAnswerOptions = async () => {

        const { data } = await doGetRadioOptions(q.id);

        setAnswerOptions(data)

        if (q.compilation_answer.length > 0) {

            const compilationAnswerId = q.compilation_answer[0]?.id;
            if (compilationAnswerId) {
                if (q.type === "radio") {
                    const { data } = await doGetOptionsAnswer(tokenToUse, q.id, compilationAnswerId);
                    setAnsweredOptions(data.compilation_answer);
                } else if (q.type === "multiple") {

                    setAnsweredOptions([]);
                    setSelectedOptions([]);

                    for (let i = 0; i < q.compilation_answer.length; i++) {
                        const { data } = await doGetOptionsAnswer(tokenToUse, q?.id, q?.compilation_answer[i]?.id);
                        if (data.compilation_answer) {
                            selectedOptions.push(data.compilation_answer);
                        }
                    }

                    setAnsweredOptions(selectedOptions);
                }
            }
        }
    }

    const getAnswerText = async () => {

        if (q.type === 'text' || q.type === 'float' || q.type === 'date') {
            if (q.compilation_answer.length > 0) {
                const { data } = await doGetAnswerText(tokenToUse, q?.id, q.compilation_answer[0]?.id);
                setAnswerText(data?.compilation_answer);
            }
        }

    }

    useEffect(() => {
        setErrorMessage(false);
        if (answeredOptions?.hasOwnProperty("child_question")) {
            setChildQuestion(answeredOptions?.child_question);
            setVisibleChildQuestionsTab(true);
        } else {
            setChildQuestion([]);
            setVisibleChildQuestionsTab(false);
        }

    }, [q, answeredOptions, updatedOption]);

    useEffect(() => {
        setErrorMessage(false);
        if (answerText?.hasOwnProperty("child_question")) {
            setChildQuestion(answerText?.child_question);
            setVisibleChildQuestionsTab(true);
        } else {
            setChildQuestion([]);
            setVisibleChildQuestionsTab(false);
        }

    }, [q, answerText, updatedOption]);




    useEffect(() => {

        const multipleChildQuestion = getMultipleChildQuestion();
        setNewMultipleChildQuestion(multipleChildQuestion);

    }, [answeredOptions, updatedOption]);

    const getMultipleChildQuestion = () => {
        if (answeredOptions && answeredOptions?.length > 0) {
            return answeredOptions.filter((r) => r?.child_question).map((r) => r.child_question);
        }
        return [];
    };

    const handleSaveTextQuestion = async (values) => {

        setLoading(true);

        let formattedValue = values;
        if (q.type === 'date') {
            formattedValue = values;
        }

        const formData = appendFormData(q.type, formattedValue, family_member_id)

        try {
            const res = q?.compilation_answer.length < 1
                ? await doPostAnswer(tokenToUse, q.id, formData)
                : await doPutAnswer(tokenToUse, q.id, answerText?.id, formData);

            if (res.status === "ok") {
                setLoading(false);
                setSaved(true);
                setErrorMessage(false);
                dispatch(postIsAnswered(true));
                if (res.data.compilation_answer?.hasOwnProperty("child_question")) {
                    setChildQuestion(res?.data?.compilation_answer?.child_question);
                    setVisibleChildQuestionsTab(true);

                } else {
                    setVisibleChildQuestionsTab(false);
                }
            } else if (res?.status === "ko") {
                setErrorMessage(res.error.message);

            }
        } catch (error) {
            // console.log('error', error);
        }
        setLoading(false);
    }

    const handleSaveRadioQuestion = async (answer_option_id) => {

        setLoading(true);

        const formData = appendFormData(q.type, answer_option_id, family_member_id);

        try {

            let res;

            if (answerProvided) {
                res = await doPutAnswer(tokenToUse, q.id, childQuestionID, formData);
            }

            else {
                if (q.compilation_answer.length < 1) {
                    res = await doPostAnswer(tokenToUse, q.id, formData);
                } else {
                    res = await doPutAnswer(tokenToUse, q.id, answeredOptions?.id, formData);
                }
            }

            if (res.status === "ok") {
                dispatch(postIsAnswered(true));
                setLoading(false);
                setSaved(true);
                setAnswerProvided(true);
                setChildQuestionID(res?.data?.compilation_answer?.id || answeredOptions?.id)

                const newChildQuestionID = res?.data?.compilation_answer?.id || answeredOptions?.id;
                const childQuestionData = await doGetChildQuestion(tokenToUse, q?.id, newChildQuestionID);

                if (childQuestionData?.data?.compilation_answer?.hasOwnProperty("child_question")) {
                    setChildQuestion(childQuestionData?.data?.compilation_answer?.child_question);
                    setVisibleChildQuestionsTab(true);
                } else {
                    setVisibleChildQuestionsTab(false);
                }

            } else if (res.status === 'ko') {
                setVisibleChildQuestionsTab(false);
            }

            // console.log('result', res);
        } catch (error) {
            // console.log('error', error);
        }
    };


    const handleSaveOption = async (option) => {

        try {

            const pickedOption = Array.isArray(option) ? option[option.length - 1] : option;

            const formData = appendFormData(q.type, pickedOption, family_member_id)

            const res = await doPostAnswer(token, q.id, formData);
            if (res.status === "ok") {

                const newAnswerOption = res.data.compilation_answer;
                setAnsweredOptions(prevOptions => [...prevOptions, newAnswerOption]);
            } else {
                setVisibleChildQuestionsTab(false);
            }

        } catch (error) {
            console.error('error', error);
        }

    };

    return (
        <>
            <Paper style={{ border: "1px solid #d6e1f3" }} sx={{ marginBottom: '3vh', padding: '2vh' }}>
                {(q.type === "text" || q.type === "float" || q.type === "date") &&
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={textValidationSchema}>
                        {({ values, errors, touched, handleChange, handleBlur }) => (

                            <FormText
                                key={`f-txt-${q.id}`}
                                answerOptions={answerOptions}
                                values={values}
                                errors={errors}
                                touched={touched}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                handleSaveTextQuestion={handleSaveTextQuestion}
                                fontSize={fontSize}
                                title={_.capitalize(q.text)}
                                saved={saved}
                                loading={loading}
                                question_id={q.id}
                                errorMessage={errorMessage}
                            />

                        )}
                    </Formik>
                }
                {q.type === "radio" &&
                    <Formik
                        enableReinitialize={true}
                        initialValues={{ inputRadio: answeredOptions?.answer_option?.id }}
                        validationSchema={radioValidationSchema}>
                        {({ values, errors, touched, handleChange, handleBlur }) => (

                            <FormRadio
                                key={`f-ra-${q.id}`}
                                fontSize={fontSize}
                                answerOptions={answerOptions}
                                values={values}
                                errors={errors}
                                touched={touched}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                handleSaveRadioQuestion={handleSaveRadioQuestion}
                                title={_.capitalize(q.text)}
                                question_id={q.id}
                                saved={saved}
                                loading={loading}
                            />

                        )}
                    </Formik>
                }
                {q.type === "multiple" &&
                    <Formik
                        enableReinitialize={true}
                        validationSchema={radioValidationSchema}
                        initialValues={''}
                    >
                        {({ values, errors, touched, handleChange, handleBlur }) => (

                            <FormMultiple
                                key={`f-mu-${q.id}`}
                                fontSize={fontSize}
                                errors={errors}
                                answerOptions={answerOptions}
                                answeredOptions={answeredOptions}
                                values={values}
                                touched={touched}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                title={_.capitalize(q.text)}
                                question_id={q.id}
                                saved={saved}
                                loading={loading}
                                handleSaveOption={handleSaveOption}
                                onSavedOption={onSavedOption}
                            />

                        )}
                    </Formik>
                }
            </Paper >
            {visibleChildQuestionsTab &&
                <Paper style={{ border: "1px solid #d6e1f3" }} sx={{
                    marginBottom: '3vh', padding: '2vh',
                    backgroundColor: '#F2F5FB',
                    width: '97%',
                    marginLeft: '3%',
                }}>
                    <ChildQuestion
                        key={`ch-${q.id}`}
                        q={childQuestion}
                        answerOptions={answerOptions}
                        family_member_id={family_member_id}
                    />
                </Paper>
            }
            {
                newMultipleChildQuestion && newMultipleChildQuestion.map((childQuestion, index) => (
                    <>
                        <Paper style={{ border: "1px solid #d6e1f3" }} sx={{
                            marginBottom: '3vh', padding: '2vh',
                            backgroundColor: '#F2F5FB',
                            width: '97%',
                            marginLeft: '3%',
                        }}>
                            <ChildQuestion
                                key={`ch-mu-${q.id}`}
                                q={childQuestion}
                                answerOptions={answerOptions}
                                family_member_id={family_member_id}
                            />
                        </Paper>
                    </>
                ))
            }
        </>
    )
}

export default Question