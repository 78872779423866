import React, { useState, useEffect } from 'react'
import { useTheme, TextField, Typography, InputAdornment, CircularProgress, FormLabel, FormControl, Grid, Alert } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import isEmpty from 'lodash/isEmpty';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

const FormText = ({
    errors,
    fontSize,
    handleSaveTextQuestion,
    handleChange,
    handleSubmit,
    index,
    saved,
    title,
    touched,
    values,
    handleBlur,
    loading,
    question_id,
    question_type,
    answerOptions,
    errorMessage
}) => {

    const theme = useTheme();

    const [selectedDate, setSelectedDate] = useState("");

    useEffect(() => {
        if (values.inputText) {
            setSelectedDate(new Date(values.inputText));
        }
    }, [values]);

    const handleDateChange = (date) => {
        setSelectedDate(date.format("YYYY-MM-DD"));
        handleSaveTextQuestion(date.format("YYYY-MM-DD"));
    };

    return (
        <FormControl fullWidth>
            <FormLabel>
                <Typography color={theme.palette.axaDeepBlue.main} fontSize={fontSize}>{title}</Typography>
            </FormLabel>
            {question_type === 'date' ? (
                <MobileDatePicker
                    name="inputText"
                    inputFormat="DD/MM/YYYY"
                    value={selectedDate}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params}
                        error={Boolean(errors.inputText)}
                        helperText={touched.inputText && errors.inputText} variant="standard" fullWidth />}
                />
            ) : (
                <>
                    <TextField
                        fullWidth
                        name="inputText"
                        id="inputText"
                        value={values.inputText}
                        error={errors.inputText}
                        onBlur={(e) => { handleSaveTextQuestion(values.inputText); handleBlur(e); }}
                        onChange={handleChange}
                        variant="standard"
                        helperText={touched.inputText && errors.inputText}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {(isEmpty(errors) && loading) ? <CircularProgress size={20} color="primary" /> : ""}
                                    {(isEmpty(errors) && saved) ? <CheckIcon /> : ""}
                                </InputAdornment>

                            ),
                        }}
                    />
                    {errorMessage &&
                        <Grid key={1} sx={{ mt: 2 }}>
                            <Alert severity="error">{errorMessage}</Alert>
                        </Grid>
                    }
                </>
            )}

        </FormControl >
    )
}

export default FormText