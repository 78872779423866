//Stepper
export const getStepperStyle = () => ({
    activeBgColor: '#000089',
    activeTextColor: '#fff',
    inactiveBgColor: '#D54141',
    inactiveTextColor: '#fff',
    completedBgColor: '#3F8542',
    completedTextColor: '#fff',
    size: '2em',
    cursor: 'pointer'
});