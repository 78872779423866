import React from 'react';
import { Box, Typography } from "@mui/material";
import { commonStyles } from '../components/commonStyles';

const PageNotFound = () => {

    return (
        <Box
            sx={{
                ...commonStyles,
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Typography variant="h4" style={{ color: 'gray' }}>
                Pagina non trovata
            </Typography>
        </Box >
    )
}

export default PageNotFound