import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const postIsAnswered = createAsyncThunk(
    '/form/question/answered',
    async (isAnswered, { rejectWithValue, getState, dispatch }) => {
        try {
            const data = isAnswered;
            return data;
        } catch (error) {
            if (!error?.response) {
                throw error;
            }
            return rejectWithValue(error?.response?.data)
        }
    }
);



const formsSlices = createSlice({
    name: 'questions',
    initialState: {
        isAnswered: false,
        selectedOptions: []
    },

    extraReducers: (builder) => {
        builder.addCase(postIsAnswered.pending, (state, action) => {
            state.loading = true;
            state.appErr = undefined;
            state.serverErr = undefined;
        });

        builder.addCase(postIsAnswered.fulfilled, (state, action) => {
            state.loading = true;
            state.isAnswered = action?.payload;
            state.appErr = undefined;
            state.serverErr = undefined;
        });

        builder.addCase(postIsAnswered.rejected, (state, action) => {
            state.loading = false;
            state.appErr = action?.payload?.message;
            state.serverErr = action?.error?.message;
        });

    },
})

export default formsSlices.reducer;