import React, { useState, useEffect } from 'react'
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import { MenuItem, Box, Typography, Grid, Select, Chip, OutlinedInput, CircularProgress } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import isEmpty from 'lodash/isEmpty';
import { doPostAnswer, doDeleteAnswer } from '../controllers/answerController';
import { useDispatch } from 'react-redux'
import { selectedOptions } from "../redux/slices/questions/questionsSlices";
import { postIsAnswered } from "../redux/slices/questions/questionsSlices";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const FormMultiple = ({ title, errors, token, loading, questionId, saved, answerOptions, answeredOptions, fontSize, onSavedOption, handleSaveOption }) => {

    const [selectedOption, setSelectedOption] = useState([]);

    useEffect(() => {

        if (answerOptions) {
            setSelectedOption(
                answeredOptions
                    .filter((option) =>
                        answerOptions.some((answerOption) => answerOption?.id === option?.answer_option?.id)
                    )
                    .map((option) => option.answer_option.id)
            );
        }

    }, [answeredOptions]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedOption(
            typeof value === 'string' ? value.split(',') : value,
        );

    };



    const handleRemove = async (valueToRemove) => {
        const optionToRemove = answeredOptions.find((option) => option?.answer_option?.id === valueToRemove);

        if (optionToRemove) {
            const id_removed = optionToRemove.id;

            try {
                const res = await doDeleteAnswer(token, questionId, id_removed);
                if (res === 'Successfully deleted') {
                    onSavedOption(true);
                }
            } catch (error) {
                console.error('error', error);
            }
        }
    };


    const findOptionById = (id) => {
        const option = answerOptions.find((option) => option.id === id);
        return option || {};
    };

    const renderValue = (selected) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => {
                const option = findOptionById(value);
                return <Chip key={value} label={option.label || option.text} />;
            })}
        </Box>
    );

    return (
        <FormControl fullWidth>
            <FormLabel>
                <Typography color="#000089" fontSize={fontSize}>{title}</Typography>
            </FormLabel>
            <Select
                multiple
                value={selectedOption}
                onChange={handleChange}
                input={<OutlinedInput label="Name" />}
                MenuProps={MenuProps}
                renderValue={renderValue}
            >
                {answerOptions.map((answer) => (
                    <MenuItem
                        key={answer.id}
                        value={answer.id}
                        onClick={() => {
                            if (selectedOption.includes(answer.id)) {
                                // console.log('handleRemove');
                                handleRemove(answer.id);
                            } else {
                                // console.log('handleSave');
                                handleSaveOption(answer.id);
                            }
                        }}
                    >
                        {answer.text}
                    </MenuItem>
                ))}
            </Select>
        </FormControl >
    )
}

export default FormMultiple