import React from 'react';
import { Link, useLocation } from 'react-router-dom'
import { commonStyles } from '../components/commonStyles';

import { Typography, Button, Box, useTheme } from '@mui/material';

// @Redux
import { getUserForm } from '../redux/slices/users/usersSlices';
import { useDispatch } from 'react-redux';

const Welcome = () => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const location = useLocation();

    dispatch(getUserForm(location.pathname));

    return (
        <Box
            sx={{
                ...commonStyles,
                alignItems: 'center',
                justifyContent: "center",
            }}
        >
            <Typography component="h1" variant="h5" color={theme.palette.axaDeepBlue.main}>
                Benvenuto
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
                <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 3, mb: 2, borderRadius: 0 }}
                >
                    <Link to="/registration">Iniziamo</Link>
                </Button>
            </Box>
        </Box >
    )
}

export default Welcome  