import axios from "axios";
import { serializeFormDataToJson } from "./helpers";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

async function doPostAnswer(form_token, question_id, formData, fm) {

    const headers = {
        headers: {}
    };

    let res = await axios.post(API_ENDPOINT + `/compilations/${form_token}/questions/${question_id}/answer`, formData, headers);
    if (res) return res.data;
}

async function doPutAnswer(form_token, question_id, answer_id, formData, fm) {

    const headers = {
        headers: {}
    };

    const rawObject = serializeFormDataToJson(formData);

    let res = await axios.put(API_ENDPOINT + `/compilations/${form_token}/questions/${question_id}/answer/${answer_id}`, rawObject, headers);
    if (res) return res.data;
}

async function doDeleteAnswer(form_token, question_id, answer_id) {

    const headers = {
        headers: {}
    };

    let res = await axios.delete(API_ENDPOINT + `/compilations/${form_token}/questions/${question_id}/answer/${answer_id}`, headers);
    if (res) return res.data;
}

export {
    doPostAnswer,
    doPutAnswer,
    doDeleteAnswer
}