import React, { useState } from 'react'
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { registerValidationSchema } from '../components/formik-validation';

// @ MUI
import { Typography, Grid, Paper } from '@mui/material';
import { commonStyles } from '../components/commonStyles';

// @ Pages
import RegisterForm from './RegisterForm';

import { Stepper } from 'react-form-stepper';
import { doPostCustomerRegistration } from '../controllers/registrationController';
import DoneIcon from '@mui/icons-material/Done';

// @ Styles
import { getStepperStyle } from '../stepperStyle.js'

const Registration = () => {

    const [step, setStep] = useState(1)
    const [errorMessage, setErrorMessage] = useState("")

    const storedData = useSelector((state => state.user));
    const { form } = storedData;

    const nextStep = () => {
        setStep(step + 1)
    }

    const prevStep = () => {
        setStep(step - 1)
    }

    const stepData = [
        {
            id: 1,
            // label: 'Registrazione',
        },
        {
            id: 2,
            // label: 'Completata',
        },
    ];

    switch (step) {
        case 1:
            return (
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <Stepper
                        steps={stepData}
                        activeStep={0}
                        styleConfig={getStepperStyle(true)}
                        className={'stepper'}
                        stepClassName={'stepper__step'}
                    />
                    <Formik
                        enableReinitialize={false}
                        initialValues={{
                            firstName: '',
                            lastName: '',
                            email: '',
                            phone: '',
                            gender: '',
                            DOB: '',
                            tax_code: '',
                            zip: '',
                            district: '',
                            country: 'IT',
                            city_birth: ''
                        }}
                        onSubmit={values => {
                            const date = new Date(values.DOB);
                            const formattedDate = date.toISOString().slice(0, 10);
                            const gender = values.gender;
                            const formattedGender = gender.charAt(0);
                            const formData = new FormData();
                            formData.append(['form'], form)
                            formData.append(['name'], values.firstName)
                            formData.append(['surname'], values.lastName)
                            formData.append(['email'], values.email)
                            formData.append(['phone'], values.phone)
                            formData.append(['gender'], formattedGender)
                            formData.append(['birth_date'], formattedDate)
                            formData.append(['fiscal_code'], values.tax_code)
                            formData.append(['birth_place'], values.city_birth)
                            formData.append(['birth_province'], values.district)
                            formData.append(['birth_zip'], values.zip)
                            formData.append(['birth_nation'], values.country)

                            try {
                                const data = doPostCustomerRegistration(formData)
                                data.then(res => {
                                    if (res.status === "ok") {
                                        //TODO::
                                        // setSuccessMessage()
                                        nextStep();
                                    } else if (res.status === "ko") {
                                        if (res.error.message) {
                                            setErrorMessage(res.error.message);
                                        } else if (res.error.validation && res.error.validation.length > 0) {
                                            setErrorMessage("Campo: " + res.error.validation[0].field + " Messaggio: " + res.error.validation[0].message);
                                        }
                                        // nextStep();
                                    }
                                })
                            } catch (error) {
                                // console.log(error);
                            }
                        }}

                        validationSchema={registerValidationSchema}
                    >
                        {(formik) => <RegisterForm
                            formik={formik}
                            onSubmit={formik.handleSubmit}
                            nextStep={nextStep}
                            prevStep={prevStep}
                            errorMessage={errorMessage}
                        />}
                    </Formik>
                </Paper>
            )
        case 2:
            return (
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 }, minHeight: '70vh' }}>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{ ...commonStyles, minHeight: commonStyles.minHeight }}
                    >
                        <DoneIcon sx={{ fontSize: '15vh' }} color="success" />
                        <Typography sx={{ variant: { sm: 'h5', xs: 'h5', md: 'h2' } }} align='center' >Registrazione Completata</Typography>
                        <Typography sx={{ variant: { sm: 'h7', xs: 'h7', md: 'h4' } }} align='center' >Controlla la tua casella di posta elettronica</Typography>
                    </Grid>
                </Paper >
            )
        default: return null
    }
}

export default Registration