function serializeFormDataToJson(formData) {
    const object = {};
    for (const [key, value] of formData.entries()) {
        // If the value is 'true' or 'false', convert it back to a boolean
        if (value === 'true') {
            object[key] = true;
        } else if (value === 'false') {
            object[key] = false;
        } else {
            object[key] = value;
        }
    }
    return JSON.stringify(object);
}


function appendFormData(question_type, value, family_member_id) {

    const formData = new FormData();

    switch (question_type) {
        case 'float':
            formData.append("answer_float", value);
            break;
        case 'date':
            formData.append("answer_date", value);
            break;
        case 'text':
            formData.append("answer_text", value);
            break;
        case 'multiple':
            formData.append("answer_option", value);
            break;
        case 'radio':
            formData.append("answer_option", value);
            break;
        default:
            console.log(`Error. Question type not available`);
    }

    if (family_member_id !== undefined) {
        formData.append("family_member", family_member_id);
    }

    return formData;
}

function logFormData(formData) {
    for (var pair of formData.entries()) {
        console.log(pair[0] + '<---- key | value ----> ' + pair[1]);
    }
}

export {
    serializeFormDataToJson,
    appendFormData,
    logFormData
}