import React, { useEffect, useState } from 'react';
import Question from '../components/Question';

import { Typography, Grid, Stack } from '@mui/material';

const Form = ({ data, fm, family_member_id }) => {
    const [results, setResults] = useState([]);

    useEffect(() => {
        const newResults = data?.questions.map((q, index) => <Question family_member_id={family_member_id} fm={fm} index={index} key={q.id} q={q} />);
        setResults(newResults);
    }, [family_member_id, data, fm]);

    const content = results?.length ? results :
        <Typography className="col-span-6 mt-12 flex" align="center">
            Nessuna domanda inserita
        </Typography>

    return (
        <>
            <Grid>
                <Stack direction="row"
                    sx={{
                        mt: 3,
                        mb: 3,
                        justifyContent: "space-between"
                    }}
                    spacing={2}>
                    <Typography variant="h6" color="#000089" gutterBottom>{data?.name}</Typography>
                </Stack>
            </Grid>
            <Grid container direction="row" alignItems="center"
                justifyContent="center" style={{ minHeight: '40vh' }}>
                <Grid item xs={12} sm={12}>
                    {content}
                </Grid>
            </Grid>
        </>

    )
}

export default Form;
