import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';

const AlertDialog = ({ openDialog, handleCloseDialog, handleOpenDialog, handleDeleteClick, family_member_id }) => {

    return (
        <>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confermi l'eliminazione dell'utente?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        L'operazione è irreversibile.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOpenDialog}>No</Button>
                    <Button onClick={() => handleDeleteClick(family_member_id)} autoFocus>
                        Si
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AlertDialog