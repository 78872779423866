import { configureStore } from '@reduxjs/toolkit';
import usersReducer from '../slices/users/usersSlices';
import formsReducer from '../slices/forms/formsSlices';
import questionsReducer from '../slices/questions/questionsSlices';

const store = configureStore({
    reducer: {
        user: usersReducer,
        form: formsReducer,
        questions:questionsReducer,
    }
})

export default store;