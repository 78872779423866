import * as React from 'react';
import { Box, Grid, Container } from '@mui/material';
import { fontSize } from './mui-params';

export default function Footer() {
    return <footer>
        <Box px={{ xs: 2, sm: 2 }}
            py={{ xs: 2, sm: 2 }}
            bgcolor="#d6e1f3"
            color="#000089"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '25vh'
            }}
        >
            <Container sx={{ fontWeight: 'light' }} maxWidth="lg" >
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={6} fontSize={fontSize}>
                        <Box sx={{ fontWeight: 500 }} borderBottom={1} marginTop={2} >Indirizzo:</Box>
                        <Box>
                            <p>AxiStudio S.r.l.</p>
                            <p>Via Cornelia Lovato, 7</p>
                            <p>36071 Arzignano (VI)</p>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} marginTop={2} fontSize={fontSize}>
                        <Box sx={{ fontWeight: 500 }} borderBottom={1}>Hai bisogno di aiuto?</Box>
                        <p>Contattaci</p>
                        <p>Cookie Policy</p>
                        {/* <Box>
                            <Link href="/" color="inherit">
                                Link2
                            </Link>
                        </Box> */}
                    </Grid>

                </Grid>
            </Container>
        </Box>
    </footer >;
}