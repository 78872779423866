import React from 'react';
import 'moment/locale/it';

// @ Mui
import Typography from '@mui/material/Typography';
import { Grid, TextField, Autocomplete, Alert, Divider, Box, CircularProgress, Button } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

const RegisterForm = ({ formik, handleChange, prevStep, nextStep, errorMessage }) => {

    return (
        < div >
            <Typography variant="h6" color="inherit" gutterBottom>Registrazione</Typography>
            <form onSubmit={formik.handleSubmit}>
                <Box sx={{ my: 3, mx: 2 }}>
                    {errorMessage !== "" &&
                        < Grid sx={{ mt: 2 }} >
                            <Alert severity="error">{errorMessage}</Alert>
                        </Grid>
                    }
                    <Grid container spacing={{ xs: 1, sm: 2 }} direction="row" alignItems="center"
                        justifyContent="center" style={{ minHeight: '30vh' }}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoFocus
                                fullWidth
                                id="firstName"
                                name="firstName"
                                label="Nome"
                                variant="standard"
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                error={Boolean(formik.errors.firstName)}
                                helperText={formik.touched.firstName && formik.errors.firstName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="lastName"
                                name="lastName"
                                label="Cognome"
                                fullWidth
                                variant="standard"
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                error={Boolean(formik.errors.lastName)}
                                helperText={formik.touched.lastName && formik.errors.lastName}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="email"
                                name="email"
                                label="Email"
                                fullWidth
                                variant="standard"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="phone"
                                name="phone"
                                label="Telefono"
                                fullWidth
                                variant="standard"
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                                error={Boolean(formik.errors.phone)}
                                helperText={formik.touched.phone && formik.errors.phone}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                name="gender"
                                id="gender"
                                noOptionsText={'Nessuna opzione'}
                                disablePortal
                                getOptionLabel={(option) => option}
                                options={["Maschio", "Femmina", "Altro"]}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        variant="standard"
                                        label="Sesso" fullWidth
                                        error={Boolean(formik.errors.gender)}

                                    />}
                                value={formik.values.gender}
                                onChange={(e, value) => formik.setFieldValue("gender", value)}
                                helpertext={formik.touched.gender && formik.errors.gender}
                            // onChange={this.onGenderChange}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="tax_code"
                                name="tax_code"
                                label="Codice Fiscale"
                                maxLength="10"
                                fullWidth
                                variant="standard"
                                value={formik.values.tax_code}
                                onChange={formik.handleChange}
                                error={Boolean(formik.errors.tax_code)}
                                helperText={formik.touched.tax_code && formik.errors.tax_code}
                            />
                        </Grid>

                    </Grid>
                </Box>
                <Divider></Divider>
                {/* style={{ outline: "2px solid grey", outlineOffset: "3px" }} */}
                <Box sx={{ my: 2, mx: 2 }} >
                    <Grid container spacing={{ xs: 2, sm: 2 }} direction="row" alignItems="center"
                        justifyContent="center" style={{ minHeight: '10vh' }}>

                        <Grid item xs={12} sm={6}>
                            <MobileDatePicker
                                name="DOB"
                                label="Data di Nascita"
                                inputFormat="DD/MM/YYYY"
                                value={formik.values.DOB}
                                onChange={(date) => formik.setFieldValue("DOB", date)}
                                renderInput={(params) => <TextField {...params} error={Boolean(formik.errors.DOB)} helperText={formik.touched.DOB && formik.errors.DOB} variant="standard" fullWidth />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="city_birth"
                                name="city_birth"
                                label="Comune di Nascita"
                                fullWidth
                                variant="standard"
                                value={formik.values.city_birth}
                                onChange={formik.handleChange}
                                error={Boolean(formik.errors.city_birth)}
                                helperText={formik.touched.city_birth && formik.errors.city_birth}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="district"
                                name="district"
                                label="Provincia di Nascita"
                                fullWidth
                                variant="standard"
                                value={formik.values.district}
                                onChange={formik.handleChange}
                                error={Boolean(formik.errors.district)}
                                helperText={formik.touched.district && formik.errors.district}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="zip"
                                name="zip"
                                label="CAP di Nascita"
                                fullWidth
                                variant="standard"
                                value={formik.values.zip}
                                onChange={formik.handleChange}
                                error={Boolean(formik.errors.zip)}
                                helperText={formik.touched.zip && formik.errors.zip}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="country"
                                name="country"
                                fullWidth
                                variant="standard"
                                label="Nazione"
                                value={formik.values.country}
                                onChange={formik.handleChange}
                                error={Boolean(formik.errors.country)}
                                helperText={formik.touched.country && formik.errors.country}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        </Grid>
                    </Grid>
                </Box>

                <div className='buttons'>
                    <Grid item xs={4} sm={1} sx={{ mt: 3 }} display="flex" justifyContent="flex-end">
                        <Button
                            type="submit"
                            color="axaDeepBlue"
                            variant="outlined"
                            size="medium"
                            disabled={Object.keys(formik.errors).length > 0}
                            sx={{
                                width: "100%",
                                '@media (min-width:600px)': {
                                    width: 'auto',
                                },
                            }}
                        >
                            {formik.isSubmitting && !formik.dirty ? (
                                <CircularProgress size={20} color="primary" />
                            ) : (
                                "Continua"
                            )}
                        </Button>
                    </Grid>
                </div>
            </form >
        </div >
    )
}

export default RegisterForm