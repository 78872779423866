import React from 'react';
import { Grid, Radio, RadioGroup, FormControlLabel, FormLabel, FormControl, CircularProgress } from '@mui/material';
import { Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import isEmpty from 'lodash/isEmpty';

const FormRadio = ({
    answerOptions,
    errors,
    fontSize,
    handleSaveRadioQuestion,
    handleChange,
    handleBlur,
    index,
    title,
    saved,
    touched,
    question_id,
    loading,
    values
}) => {
    return (
        <FormControl fullWidth>
            <FormLabel>
                <Typography color="#000089" fontSize={fontSize}>
                    {title}
                </Typography>
            </FormLabel>
            <Grid container alignItems="center">
                <Grid item xs={12} sm={9}>
                    <RadioGroup
                        row
                        aria-labelledby="inputRadio"
                        name="inputRadio"
                        value={values.inputRadio || ''}
                        error={errors.inputRadio}
                        onBlur={handleBlur}
                        onChange={(e) => {
                            handleChange(e);
                            handleSaveRadioQuestion(e.target.value);
                        }}
                    >
                        {answerOptions?.map((a, index) => (
                            <FormControlLabel
                                key={index}
                                value={a?.id}
                                control={<Radio sx={{ '&.Mui-checked': { color: '#000089' } }} />}
                                label={a?.text}
                            />
                        ))}
                    </RadioGroup>
                </Grid>
                <Grid item xs={12} sm={3} container justifyContent="flex-end">
                    {(isEmpty(errors) && loading) && (
                        <Grid item>
                            <CircularProgress size={20} color="primary" />
                        </Grid>
                    )}
                    {(isEmpty(errors) && saved && !loading) && (
                        <Grid item>
                            <CheckIcon />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </FormControl>
    );
};

export default FormRadio;