import axios from "axios";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

async function doGetForms(url) {

    // TODO::Aggiornare con la chiamata corretta /forms
    const headers = {
        headers: {
            "content-type": "application/json"
        }
    };

    // console.log('API_ENDPOINT-->DO_GET_FORMS', API_ENDPOINT)

    let res = await axios.get(API_ENDPOINT + `/backoffice/forms`, headers);

    if (res) return res.data;
}

async function doGetUserForm(url) {

    // TODO::Aggiornare con la chiamata corretta /forms
    const headers = {
        headers: {
            "content-type": "application/json"
        }
    };

    // console.log('API_ENDPOINT-->DO_GET_USER_FORM', API_ENDPOINT)

    let res = await axios.get(API_ENDPOINT + `/backoffice/forms`, headers);
    localStorage.setItem('user_form', JSON.stringify(res?.data?.data?.find(el => el?.url === url.replace(/^\/|\/$/g, '')).id))
    if (res) return res?.data?.data?.find(el => el?.url === url.replace(/^\/|\/$/g, '')).id;

}

async function doPutToggleFamilyMembers() {

    const headers = {
        headers: {
            "x-language": "en"
        }
    };

    const form_token = localStorage.getItem('form_token');

    let response = await axios.put(API_ENDPOINT + `/compilations/${form_token.replace(/"/g, '')}/is_family_filled`, headers)
    if (response) return response;

}

export {
    doGetForms,
    doGetUserForm,
    doPutToggleFamilyMembers
}